/* Overall wrapper */

.monthly {
  background: #f3f3f5;
  color: #545454;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  .vacation,
  .exams,
  .both {
    &:after {
      position: absolute;
      content: "";
    }
  }
  .vacation:after {
    top: 24px;
    height: 24px;
    width: 100%;
    background-color: #009fda;
  }
  .exams:after {
    top: 24px;
    height: 24px;
    width: 100%;
    background-color: #fecb00;
  }
  .both:after {
    top: 24px;
    height: 24px;
    width: 100%;
    border-top: 12px solid #009fda;
    border-bottom: 12px solid #fecb00;
  }
}

/* Top bar containing title, navigation, and buttons */

.monthly-header {
  position: relative;
  text-align: center;
  padding: 0.5em;
  background: #fff;
  height: 3em;
  box-sizing: border-box;
}

/* Center area of top bar containing title and buttons */

.monthly-header-title {
  text-transform: uppercase;
}

/* Buttons for reverting to "today", and closing event list */

.monthly-header-title a:link,
.monthly-header-title a:visited {
  display: inline-block;
  border: 1px solid #ccc;
  color: #545454;
  text-decoration: none;
  height: 1.75em;
  line-height: 1.65em;
  padding: 0 0.65em 0 0.65em;
  box-sizing: border-box;
  transition: background 0.1s;
}

/* Add some roundy-ness */

.monthly-header-title a:first-of-type {
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.monthly-header-title a:last-of-type {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.monthly-header-title a:hover {
  background: #8b8b8b;
  border: 1px solid #8b8b8b;
  color: #fff;
}

.monthly-header-title a:active {
  background: #222;
  border: 1px solid #222;
  transition: none;
}

/* current month/yr block */

.monthly-header-title-date,
.monthly-header-title-date:hover {
  background: #eee !important;
  border: 1px solid #ccc !important;
  color: #545454 !important;
  cursor: default;
}

/* Button to reset to current month */

.monthly-reset {
  border-left: 0 !important;
}

.monthly-reset::before {
  content: "\21BB";
  margin-right: 0.25em;
}

/* Button to return to month view */

.monthly-cal {
  border-right: 0 !important;
}

.monthly-cal::before {
  content: "\2637";
  margin-right: 0.25em;
}

/* wrapper for left/right buttons to make the clickable area bigger */

.monthly-prev,
.monthly-next {
  position: absolute;
  top: 0;
  width: 3em;
  height: 100%;
  opacity: 0.5;
}

.monthly-prev {
  left: 0;
}

.monthly-next {
  right: 0;
}

.monthly-prev:hover,
.monthly-next:hover {
  opacity: 1;
}

/* Arrows */

.monthly-prev:after,
.monthly-next:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: #222;
  width: 0.6em;
  height: 0.6em;
  margin: -0.4em 0 0 -0.4em;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Left Arrow */

.monthly-prev:after {
  border-width: 0 0 0.2em 0.2em;
}

/* Right Arrow */

.monthly-next:after {
  border-width: 0.2em 0.2em 0 0;
}

/* Day of the week headings */

.monthly-day-title-wrap {
  display: table;
  table-layout: fixed;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
}

.monthly-day-title-wrap div {
  width: 14.28% !important;
  display: table-cell;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

/* Calendar days wrapper */

.monthly-day-wrap {
  display: table;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

.monthly-week {
  display: table-row;
  width: 100%;
}

/* Calendar Days */

.monthly-day,
.monthly-day-blank {
  width: 14.28% !important;
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  color: inherit;
  background: #fff;
  box-shadow: 0 0 0 1px #ebebeb;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  padding: 0;
  text-decoration: none;
}

/* Trick to make the days' width equal their height */

.monthly-day:before {
  content: "";
  display: block;
  padding-top: 100%;
  float: left;
}

/* Hover effect for non event calendar days */

.monthly-day-wrap > a:hover {
  background: #a1c2e3;
}

/* Days that are part of previous or next month */

.monthly-day-blank {
  background: #f3f3f5;
}

/* Event calendar day number styles */

.monthly-day-event > .monthly-day-number {
  position: absolute;
  line-height: 1em;
  top: 0.2em;
  left: 0.25em;
}

/* Non-Event calendar day number styles */

.monthly-day-pick > .monthly-indicator-wrap {
  margin: 0;
}

.monthly-day-pick > .monthly-day-number:before,
.monthly-day-pick > .monthly-day-number:after {
  content: "";
  display: block;
  padding-top: calc(50% - 0.8em);
  width: 0;
  height: 0;
}

/* Days in the past in "picker" mode */

.monthly-past-day:after {
  content: "";
  width: 150%;
  height: 2px;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
}

.monthly-past-day:hover {
  background: #fff !important;
}

/* Current day style */

.monthly-today .monthly-day-number {
  color: #fff;
  background: #ea6565;
  border-radius: 0.75em;
  top: 0.08em;
  left: 0.05em;
  font-size: 0.9em;
  padding: 0;
  width: 1.25em;
  height: 1.25em;
  line-height: 1.25em;
  text-align: center;
}

.monthly-day-pick.monthly-today .monthly-day-number {
  padding: 0.15em;
  margin: calc(50% - 0.7em) auto auto auto;
  font-size: 1em;
}

/* Wrapper around events */

.monthly-indicator-wrap {
  position: relative;
  text-align: center;
  line-height: 0;
  max-width: 1.5em;
  margin: 0 auto;
  padding-top: 1.2em;
}

/* Event indicator dots */

.monthly-day .monthly-event-indicator {
  display: inline-block;
  margin: 0.05em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  vertical-align: middle;
  background: #7ba7ce;
}

.monthly-day .monthly-event-indicator span {
  color: transparent;
}

.monthly-day .monthly-event-indicator:hover {
  cursor: pointer;
}

/* Listing of events under calendar */

.monthly-event-list {
  background: rgba(233, 235, 236, 0.9);
  overflow: auto;
  position: absolute;
  top: 2.5em;
  width: 100%;
  height: calc(100% - 2.5em);
  display: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

/* Days in Events List */

.monthly-list-item {
  position: relative;
  padding: 0.5em 0.7em 0.25em 4em;
  display: none;
  border-top: 1px solid #d6d6d6;
  text-align: left;
}

.monthly-list-item:after {
  padding: 0.4em 1em;
  display: block;
  margin-bottom: 0.5em;
}

.monthly-event-list .monthly-today .monthly-event-list-date {
  color: #ea6565;
}

/* Events in Events List */

.monthly-event-list .listed-event {
  display: block;
  color: #fff;
  padding: 0.4em 1em;
  border-radius: 0.2em;
  margin-bottom: 0.5em;
}

.monthly-list-item a:link,
.monthly-list-item a:visited {
  text-decoration: none;
}

.item-has-event {
  display: block;
}

.item-has-event:after {
  display: none !important;
}

.monthly-event-list-date {
  width: 4em;
  position: absolute;
  left: 0;
  top: 1.2em;
  text-align: center;
  font-weight: bold;
  line-height: 1.2em;
}

.monthly-list-time-start,
.monthly-list-time-end {
  font-size: 0.8em;
  display: inline-block;
}

.monthly-list-time-end:not(:empty):before {
  content: "\2013";
  padding: 0 2px;
}

/* Events List custom webkit scrollbar */

.monthly-event-list::-webkit-scrollbar {
  width: 0.75em;
}

/* Track */

.monthly-event-list::-webkit-scrollbar-track {
  background: none;
}

/* Handle */

.monthly-event-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border: 1px solid #e9ebec;
  border-radius: 0.5em;
}

.monthly-event-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Language-specific. Default is English. */

.monthly-reset:after {
  content: "Today";
}

.monthly-cal:after {
  content: "Month";
}

.monthly-list-item:after {
  content: "No Events";
}

.monthly-locale-fr .monthly-reset:after {
  content: "aujourd'hui";
}

.monthly-locale-fr .monthly-cal:after {
  content: "mois";
}

.monthly-locale-fr .monthly-list-item:after {
  content: "aucun événement";
}

/*
Calendar shows event titles if the device width allows for at least 3em per day (rounded
up to 25em total). This assumes the calendar font is close to the baseline font size and
the calendar takes up close to the full media width as the window is made smaller or the
font is zoomed. If one or both of these is not true, this will need to be overridden by
a layout-specific width, or you will need to use a library like css-element-queries to 
establish the rules based on the calendar element width rather than the device width.
*/

@media (min-width: 25em) {
  .monthly-day-event {
    padding-top: 1.3em;
  }
  .monthly-day-event > .monthly-indicator-wrap {
    width: auto;
    max-width: none;
  }
  .monthly-indicator-wrap {
    padding: 0;
  }
  .monthly-day:before {
    padding-top: calc(100% - 1.2em);
  }
  .monthly-day .monthly-event-indicator {
    display: block;
    margin: 0 0 1px 0;
    width: auto;
    height: 1.5em;
    line-height: 1.2em;
    padding: 0.125em 0 0.1em 0.125em;
    border-radius: 0;
    overflow: hidden;
    background-color: #333;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .monthly-day .monthly-event-indicator.monthly-event-continued {
    box-shadow: -1px 0 0 0;
  }
  .monthly-day .monthly-event-indicator span {
    display: block;
    width: auto;
    margin: 0;
    color: #fff;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
