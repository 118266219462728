// Directions map css
#owl[data-type='directions'] {
	height: 0px;
    overflow: hidden;
    background-color: #212121;
    @media screen and (min-width: 1024px) {
		height: 240px;
    	display: block;
    }
    @media screen and (min-width: 1300px) {
		height: 388px;
    	display: block;
    }
}

#lib-map[data-type='directions'] {
	height: 100%;
	min-height: 480px;
	@media screen and (min-width: 1024px) {
		height: 100%;
		min-height: 440px;
	}
	@media screen and (min-width: 1300px) {
		height: 100%;
		min-height: 588px;
	}
}

.map {
    padding-right: 0;
	padding-left: 0;

	.gm-style-iw + div {
		display: none;
	}
}

.carousel {
    padding-left: 0;
    padding-right: 0;
}

.lib-name {
	height: 100%;
    padding: 24px;
    color: #fff;
    background-color: #212121;
    @media screen and (min-width: 1024px) {
		height: 200px;
	}
}