.lib-opening-home {
    overflow: hidden;
    @media (min-width: $screen-md-min) {
        margin-right: $space;
    }
    .opening-map {
        position: relative;
        overflow: hidden;
        min-height: 300px;
        > .equalizer-inner {
            width: 100%;
            height: 100%;
        }
    }
    iframe {
        margin-top: -50px;
        width: 100%;
        height: 100%;
        height: calc(100% + 50px);
    }
    .opening-times {
        display: table;
        width: 100%;
        > div > div {
            padding: $space-sm;
            border-bottom: 1px solid $color-brand-2;
            @media (min-width: $screen-md-min) {
                padding: $space-sm 0;
            }
        }
        > div:last-child div {
            border: 0;
        }
    }
}
