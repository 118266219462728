/**
 * Library Gallery
*/

//Salvattore.js setup using BS grid

.gallery[data-columns] {
    &::before {
       display:block;
       visibility:
       hidden;
       position:absolute;
       font-size:1px;
    }
    @media (max-width: #{($screen-xs-min - 1)}) {
        &::before {
            content: '1 ';
        }
    }
    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        &::before {
            content: '2 .col-xs-6';
        }
    }
    @media (min-width: $screen-sm-min) {
        &::before {
            content: '3 .col-sm-4';
        }
    }
}

//Fade in effect for gallery
.gallery {
    opacity: 0;
    transition: all 0.25s ease;
    &.active {
        opacity: 1;
    }
    [class*="col-"] {
        opacity: 0;
        animation: fadeSeq .5s ease forwards;
        &:nth-child(2) {
            animation-delay: .25s;
        }
        &:nth-child(3) {
            animation-delay: .5s;
        }
    }

    .lt-ie9 &,
    .ie9 &,
    .no-js & {
        opacity: 1;
        [class*="col-"] {
            opacity: 1;
        }
    }
}

@keyframes fadeSeq {
    100% { opacity: 1; }
}

.gallery-img {

    margin-bottom: $grid-gutter-width;
    position: relative;

    img {
        margin: 0;
    }

    .modal-info {
        display: none;
    }

    a {
        transition: .8s opacity;
        border: 0;
        &:hover {
            border: 0;
            img {
                border: 0;
            }
        }
    }

    .gallery-heading {

        background: $color-masthead;
        padding: $space-xs;

        h4 {
            margin: 0;
            color: white;
        }

        @media (min-width: $screen-md-min) {
            background: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &:after {
                content:"";
                position: absolute;
                top: 10px;
                left: 10px;
                bottom: 10px;
                right: 10px;
                background: #fff;
                opacity: 0;
                transform: scale(0.9);
                transition: all 0.25s ease;
            }

            h4 {
                transition: all 0.25s ease;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                text-align: center;
                color: $color-font;
                padding: $space;
                hyphens: auto;
                z-index: 2;
                opacity: 0;
                transition: all 0.25s ease;

                span {
                    display: block;
                }
            }
        }
    }

    a:hover .gallery-heading {
        &:after {
            opacity: 0.85;
            transform: scale(1);
        }
        h4 {
            opacity: 1;
        }
    }


    a > img {
        padding: 0;
        border: 0;
    }
}
