.monthly-day {
  .no-outline &:focus {
    outline: none;
  }
}

.monthly-today {
  background-color: #f2f2f2 !important;
}

.selected-date {
  background-color: darken(#f2f2f2, 8%);
}

.date-info {
  position: absolute;
  width: 200px;
  min-height: 32px;
  box-shadow: 0 0 2px #000;
  z-index: 1;
  background: #fff;
  padding: 18px;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.4);
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: 18px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 12px solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.25);
  }
  .the-date {
    text-transform: uppercase;
    font-family: freight-sans-pro, sans-serif;
    font-size: 18px;
    text-align: center;
  }
  .date-open {
    text-align: center;
    font-size: 30px;
    margin: 12px 0;
  }
  .date-core {
    text-transform: uppercase;
  }
}

.closed {
  display: none;
}

.desktop-calendar {
  @media (max-width: $screen-md - 1) {
    display: none;
  }
}

.monthly-today .monthly-day-number {
  background: #6f9a1b;
}

.monthly-header {
  position: relative;
  text-align: center;
  background: #fff;
  height: 5em;
  box-sizing: border-box;
  border-bottom: 1px solid #4a4a4a;
}

.monthly-prev,
.monthly-next {
  font-size: 1.5em;
}

.monthly-day-title-wrap {
  padding-top: 1em;
}

.monthly-day-title-wrap div {
  font-size: 1.8rem;
  padding: 12px 0;
}

// Month styles
.monthly-header-title a:last-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.monthly-header-title a:first-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.monthly-header-title a:link,
.monthly-header-title a:visited {
  border: 0 !important;
  height: 100%;
  background: transparent !important;
}

.monthly-header-title-date,
.monthly-header-title-date:hover {
  background: transparent;
  font-size: 3.2rem;
  line-height: 3.2rem;
}

.monthly-reset {
  &:hover {
    color: #777 !important;
  }
}

.calendar-key {
  display: block;
  margin-top: 12px;
  @media (max-width: $screen-sm) {
    display: none;
  }
  div {
    display: inline;
    padding-left: 24px;
    position: relative;
    &:first-child {
      margin-right: 24px;
    }
  }
  .green::before,
  .orange::before {
    position: absolute;
    left: 0;
    top: 6px;
    height: 12px;
    width: 12px;
    content: "";
  }
  .green::before {
    background-color: #009fda;
  }
  .orange::before {
    background-color: #fecb00;
  }
}