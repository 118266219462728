//TODO: fix for library buttons

.si-content-wrapper {
	max-width: 250px;
	padding: 24px;
	color: #777;
	.uppercase {
		text-transform: uppercase;
	}
}

.item {
	.lib-info {
		color: #fff;
		height: 100%;
		padding: 18px;
		h1, h2, h3, h4, h5, h6 {
			font-family: freight-display-pro, sans-serif;
		}
		@media screen and (min-width: 768px) {
			height: 200px;
			padding: 24px;
		}
	}
	.item-bg-img {
		background-size: cover;
		background-repeat: none;
		background-position: center center;
		@media( max-width: 996px ) {
			height: 0px;
		}
		@media( min-width: 996px ) {
			height: 240px;
		}
		@media( min-width: 1300px ) {
			height: 388px;
		}
	}
}


// Locations map CSS
#owl[data-type='locations'] {
	height: 100%;
    overflow: hidden;
    background-color: #212121;
    @media screen and (min-width: 1024px) {
		height: 480px;
    }
    @media screen and (min-width: 1300px) {
		height: 588px;
    }
}

#map[data-type='locations'] {
	height: 100%;
	min-height: 480px;
	@media screen and (min-width: 1024px) {
		height: 440px;
    }
    @media screen and (min-width: 1300px) {
		height: 588px;
    }
}

.owl-buttons-library {
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	height: 60px;
	background-color: #111;
	display: none;

	.owl-prev, .owl-next {
		height: 60px;
		width: 60px;
		background-repeat: no-repeat;
    	background-position: 50%;
    	display: inline-block;
    	background-color: #212121;
    	transition: .2s ease all;
    	&:hover {
    		background-color: lighten( #212121, 10% );
    	}
	}

	.owl-prev {
		background-image: url('//www.leeds.ac.uk/site/custom_scripts/ug-open-days/img/template-build/ui/left-12-light.svg');
		margin-right: 1px;
	}

	.owl-next {
		background-image: url('//www.leeds.ac.uk/site/custom_scripts/ug-open-days/img/template-build/ui/right-12-light.svg');
	}

	@media screen and ( min-width: 996px ) {
		display: block;
	}
}