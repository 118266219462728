.heading-underline-full {
	position: relative;
    margin-top: 8px;
    margin-bottom: 16px;
    font-family: "freight-display-pro", sans-serif;
    @media( min-width: 480px ) {
    	margin-bottom: 32px;
    }
    &:before {
    	content: "";
	    position: absolute;
	    width: 100%;
	    height: 1px;
	    bottom: -12px;
	    left: 0;
	    background-color: $color-font;
    }
}

.footer-middle-light {
	background-color: #333;
}

.dark-bg-btn {
	border: 1px solid white;
    padding: 10px;
    background: #212122;
    padding: 8px 36px;
    color: #fff;
    &:hover {
    	background: #080808;
    	color: #fff;
    }
    &:focus, &:visited, &:active {
    	color: #fff;
    }
}

.newsletter-heading {
	color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.65);
    font-family: "freight-display-pro", sans-serif;
    margin-bottom: 24px;
}

.footer-newsletter {
    border-bottom: 1px solid #111111;
}

.newsletter {
    margin: 0 auto;
    text-align: center;
    width: 84%;

    @media( min-width: 768px ) {
	    input {
	    	float: left;
	    	width: 80%
	    }
	    .btn {
	    	float: right;
	    	max-width: 20%;
	    }
	}

	@media( min-width: 996px ) {
    	width: 66%;
    	input {
    		width: 78%;
    	}
    	.btn {
    		max-width: 22%;
    	}
    }
}

