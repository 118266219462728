.opening-hours {
  .opening-hours-title-area {
    height: 5em;
    box-sizing: border-box;
    border-bottom: 1px solid #4a4a4a;
    padding: 0.5em 0;
    .title {
      font-size: 3.2rem;
      .title-date {
        font-size: 2rem;
        margin-left: 6px;
        color: #777;
      }
    }
  }
  .opening-times {
    padding-top: 1em;
    .opening-times-item {
      padding: 12px 0;
      .item {
        font-size: 1.8rem;
        text-transform: uppercase;
      }
      .time-item {
        font-size: 2.4rem;
        line-height: 2.4rem;
      }
      .time-item.week {
        line-height: 3rem;
      }
      .core {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }
    .p-t-s {
      padding-top: 0;
    }
    .times-disclaimer {
      padding-top: 18px;
      color: #777;
    }
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-spinner {
  position: relative;
}

.lds-spinner div {
  left: 94px;
  top: 28px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1.2s infinite;
  animation: lds-spinner linear 1.2s infinite;
  background: #777777;
  width: 12px;
  height: 48px;
  border-radius: 40%;
  -webkit-transform-origin: 6px 72px;
  transform-origin: 6px 72px;
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -1.05s;
  animation-delay: -1.05s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.lds-spinner {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
