.a-z {
    border-bottom: 1px solid $color-border;
    padding: $space-xs 0;
    margin: 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
    li {
        list-style: none;
        width: 30px;
        float: left;
        @media (min-width: $screen-xs-min) {
            flex-grow: 1;
            width: 7.69%;
        }
        @media (min-width: $screen-sm-min) {
            width: 3.84%;
        }
        a,
        span {
            display: block;
            text-align: center; //border: 1px solid red;
            background: $color-module;
            text-transform: uppercase;
            color: $color-font;
            padding: 4px 6px;
            margin: 2px;
            font-weight: $font-weight-semibold;            
        }
        a:hover {
            background: $color-module-dark;      
            text-decoration: none;      
        }
        span {
            color: $color-font-lighter;
            background: white;
        }
    }
}