.mobile-cal-month-display {
  padding-bottom: 15px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: block;
  .month {
    font-weight: 400;
  }
  @media (min-width: $screen-md) {
    display: none;
  }
}

/*
 NEW MOBILE CAL
*/

.calendar-key-mobile {
  position: relative;
  font-size: 16px;
  margin-top: 8px;
  padding: 0 15px;
  .vacation::before {
    background-color: #009fda;
  }
  .exam:before {
    background-color: #fecb00;
  }
  .vacation::before,
  .exam:before {
    position: absolute;
    left: 0;
    top: 3px;
    height: 16px;
    width: 24px;
    content: "";
  }
  div {
    display: inline;
    padding-left: 32px;
    position: relative;
  }
  div:first-child {
    margin-right: 24px;
  }
  @media (min-width: $screen-md) {
    display: none !important;
  }
}

.mobile-cal-new {
  overflow: hidden;
  @media (min-width: $screen-md) {
    display: none !important;
  }
  .vacation,
  .exams,
  .both {
    &:after {
      position: absolute;
      content: "";
      left: 10%;
    }
  }
  .vacation:after {
    bottom: 0;
    height: 16px;
    width: 80%;
    background-color: #009fda;
  }
  .exams:after {
    bottom: 0;
    height: 16px;
    width: 80%;
    background-color: #fecb00;
  }
  .both:after {
    bottom: 0;
    height: 16px;
    width: 80%;
    border-top: 8px solid #009fda;
    border-bottom: 8px solid #fecb00;
  }
  .cal-item {
    text-align: center;
    background-color: #f2f2f2;
    box-sizing: border-box;
    .cal-day {
      padding-top: 12px;
      font-size: 18px;

      // Custom size for really, really small screens
      @media(max-width: 360px) {
        padding-top: 8px;
        font-size: 16px;
      }

      @media(min-width: $screen-xs) {
        padding-top: 12px;
        font-size: 20px;
      }

      // Custom size for a more graceful increase in device size
      @media(min-width: 600px) {
        padding-top: 20px;
        font-size: 24px;
      }
    }
    .cal-number {
      font-size: 30px;

      // Custom size for really, really small screens
      @media(max-width: 360px) {
        font-size: 24px;
      }

      @media(min-width: $screen-xs) {
        font-size: 36px;
      }

      // Custom size for a more graceful increase in device size
      @media(min-width: 600px) {
        font-size: 48px;
      }
    }
  }
  .owl-prev,
  .owl-next {
    background: none;
    border: 0;
    position: absolute;
    height: 100%;
    width: 42px;
    top: 0;
    text-align: center;
    @include tk-icon;
    &:before {
      font-size: 4rem;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  .owl-item {
    height: 100%;
  }
  .owl-next {
    right: 0;
    background: linear-gradient(to right, transparent, #fff);
    &:before {
      @include tk-icon-chevron-right();
    }
  }
  .owl-prev {
    left: 0;
    background: linear-gradient(to left, transparent, #fff);
    &:before {
      @include tk-icon-chevron-left();
    }
  }
  .today,
  .mobile-selected-date {
    background-color: #ccc;
  }
}