

.jadu-cms .lead {
	font-family: freight-display-pro, sans-serif;
}

.map-wrapper {
	background: rgb( 17, 17, 17 );
}

.loc-tabs {
	text-align: center;
	display: block;
	padding: 12px;
	background-color: #111;

	.active {
		color: #aa8f6f;
	}

	a {
		color: #fff;
		font-size: 16px;
		text-transform: uppercase;
		transition: .2s ease all;
		padding: 4px 6px;
		border-right: 1px solid #fff;
		display: inline-block;
		word-wrap: break-word;
		&:hover {
			color: #aa8f6f;
			text-decoration: none;
		}
		&:visited, &:focus, &:active {
			text-decoration: none;
		}
		&:last-child {
			border-right: 0;
		}

		@media( min-width: 1300px ) {
			font-size: 18px;
			padding: 6px 12px;
		}
	}
}

.lib-iframe {
	overflow: hidden;

	iframe {
		width: 100%;
    	border: 0;
    	min-height: 320px;

    	@media( min-width: 1300px ) {
    		min-height: 560px;
    	}
	}
}

.key {
	margin-top: 12px;
	div {
		position: relative;
		height: 40px;
		margin-bottom: 18px;
		.key-icon {
			width: 36px;
			height: 36px;
			position: absolute;
			background-color: #fff;
			left: 0;
			top: 0;
			.tk-icon {
				padding: 2px;
    			position: absolute;
    			left: 0;
    			top: 0;
    			font-size: 32px;
			}
		}
		p {
			position: absolute;
			top: 10px;
			left: 48px;
			padding-right: 12px;
			font-size: 12px;
		}
	}
}

.hidden-sm-down {
	display: none;

	@media( min-width: 996px ) {
		display: block;
	}
}

.hidden-md-up {
	display: none;

	@media( max-width: 995px ) {
		display: block;
	}
}